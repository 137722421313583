import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../app/store";
import { useAppNotifications } from "../../components/LMNotifications";
import { OrderInfo, OrderTimeline } from "../../modules/orders";
import { OrderProducts } from "../../modules/orders/OrderProducts";
import { ShipmentInfo } from "../../modules/orders/ShipmentInfo";
import { fetchOrder } from "../../modules/orders/slice";
import styles from "./Order.module.css";
import { useOrderConnection } from "../../modules/orders/hooks";

export const Order = () => {
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const { contextHolder } = useAppNotifications();
  useOrderConnection(params.orderId as string);

  const { selectedOrder } = useSelector((state: RootState) => {
    return state.orders;
  });

  const handleFetchOrderDetails = useCallback(() => {
    if (!params.orderId) return;
    dispatch(fetchOrder({ orderId: params.orderId }));
  }, [params, dispatch]);

  useEffect(() => {
    handleFetchOrderDetails();
  }, [handleFetchOrderDetails]);

  if (!selectedOrder) return null;

  return (
    <div className={styles.container}>
      {contextHolder}
      <div className={styles.content}>
        <OrderInfo order={selectedOrder} />
        {selectedOrder.shipping && (
          <ShipmentInfo
            shipping={selectedOrder.shipping}
            orderId={selectedOrder.id}
          />
        )}
        <OrderProducts
          products={selectedOrder.products}
          collectedProducts={selectedOrder.collectedProducts}
        />
      </div>
      <div className={styles.timeline}>
        <OrderTimeline
          order={selectedOrder.statusTimeline}
          shipping={selectedOrder.shipping?.statusTimeline || []}
        />
      </div>
    </div>
  );
};
